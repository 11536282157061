import React, { useEffect, useState } from "react";
import axios from "axios";
import useUserParam from "../hooks/useUserParam";
import { Button, FormControl, Table } from "react-bootstrap";
import config from "../config";
import HistoryDetails from "../components/HistoryDetails";
import PrepareButton from "../components/PrepareButton";
import usePagination from "../hooks/usePagination";
import { X } from "react-bootstrap-icons";

function History() {
   const user = useUserParam();

   const [expanded, setExpanded] = useState();
   const [filter, setFilter] = useState("");
   const [filteredList, setFilteredList] = useState([]);
   const [rawHistoryList, setRawHistoryList] = useState([]);
   const { subset: historyData, Controller } = usePagination(filteredList);

   useEffect(() => {
      axios
         .get(config.urls.history, {
            params: {
               user
            }
         })
         .then(
            res => {
               const data = res.data.sort((a, b) => new Date(b.created) - new Date(a.created));
               console.log(data);
               setRawHistoryList(data);
            },
            err => {
               console.warn("Failed to get history");
               console.error(err);
            }
         );
   }, [user]);

   useEffect(() => {
      const sublist = rawHistoryList.filter(
         r =>
            r.company?.toLowerCase().includes(filter) || r.jobTitle?.toLowerCase().includes(filter)
      );
      setFilteredList(sublist);
   }, [filter, rawHistoryList]);

   return (
      <div>
         <div className="d-flex p-2 align-items-center">
            <FormControl
               value={filter}
               placeholder="Search Company or Job Title"
               onChange={e => setFilter(e.target.value.toLowerCase())}
            />
            <X
               size={25}
               className={`cursor-pointer ${!filter ? "d-none" : ""}`}
               style={{ position: "absolute", right: "1rem" }}
               onClick={() => setFilter("")}
            />
         </div>
         <Table className="w-100 h-100" borderless>
            <colgroup>
               <col
                  span="1"
                  style={{
                     width: "4%"
                  }}
               />
               <col
                  span="2"
                  style={{
                     width: "32%"
                  }}
               />
               <col
                  span="1"
                  style={{
                     width: "10%"
                  }}
               />
               <col
                  span="1"
                  style={{
                     width: "24%"
                  }}
               />
            </colgroup>
            <thead>
               <tr>
                  <th>#</th>
                  <th>Company</th>
                  <th>Job Title</th>
                  <th>Date</th>
                  <th></th>
               </tr>
            </thead>
            <tbody>
               {historyData.map(row => {
                  const created = new Date(row.created);
                  return (
                     <React.Fragment key={row.id}>
                        <tr style={{ borderTop: "2px solid whitesmoke" }}>
                           <td data-col="ID#">{row.id}</td>
                           <td data-col="Company">{row.company || "\u00a0"}</td>
                           <td data-col="Job Title">{row.jobTitle || "\u00a0"}</td>
                           <td data-col="Time">
                              {created.toLocaleDateString("default", {
                                 day: "numeric",
                                 month: "numeric",
                                 year: "numeric"
                              })}
                              <br />
                              {created.toLocaleTimeString("default", {
                                 hour: "numeric",
                                 minute: "2-digit"
                              })}
                           </td>
                           <td>
                              <div className="horizontal-button-list">
                                 <Button
                                    className="list-button"
                                    variant="outline-secondary"
                                    onClick={() => setExpanded(e => (e !== row.id ? row.id : null))}
                                 >
                                    {row.id !== expanded ? "Show " : "Hide "}
                                    Resume
                                 </Button>
                                 {/* {row.id !== expanded ? (
                                 <ChevronDown role="button" onClick={() => setExpanded(row.id)} />
                              ) : (
                                 <ChevronUp role="button" onClick={() => setExpanded()} />
                              )} */}
                                 <PrepareButton history={row} />
                              </div>
                           </td>
                           <td></td>
                        </tr>
                        <tr hidden={row.id !== expanded}>
                           <td></td>
                           <td colSpan={4} className="pb-4">
                              <HistoryDetails generation={row} />
                           </td>
                           <td></td>
                        </tr>
                     </React.Fragment>
                  );
               })}
            </tbody>
         </Table>
         <Controller />
      </div>
   );
}

export default History;

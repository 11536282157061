import React from "react";
import {
   Button
   // Spinner
} from "react-bootstrap";

/**
 * @typedef {Object} ResultAreaProps
 * @prop {string} label
 * @prop {string} value
 * @prop {React.MouseEventHandler<HTMLButtonElement>} regenerate
 * @prop {boolean} spinner
 */
/**
 * @param {ResultAreaProps} props
 */
function ResultArea(props) {
   const { label, value, className, ...rest } = props;
   return (
      <div className={`result-area ${className || ""}`} {...rest}>
         <p>{label}</p>
         <textarea value={value} readOnly></textarea>
         <div className="bottom-right-position d-flex gap-1">
            {/* <Button
               size="sm"
               variant="success"
               disabled={!value || props.spinner}
               onClick={props.regenerate}
            >
               Regenerate
               {props.spinner ? <Spinner size="sm" style={{ marginLeft: "0.5rem" }} /> : null}
            </Button> */}
            <Button
               size="sm"
               variant="success"
               hidden={!value}
               //disabled={!value}
               onClick={() => navigator.clipboard.writeText(value)}
            >
               Copy
            </Button>
         </div>
      </div>
   );
}

export default ResultArea;

import { useCallback, useEffect, useState } from "react";

function usePaceCoach() {
   const [duration, setDuration] = useState(0);
   const [pace, setPace] = useState(0);
   const [_, setStartTime] = useState();
   const [words, setWords] = useState(0);

   const updateDuration = useCallback(start => {
      const msDifference = Date.now() - start.getTime();
      const minuteCount = msDifference / (1000 * 60);
      setDuration(d => d + minuteCount);
   }, []);

   const startTimer = useCallback(() => {
      setStartTime(st => {
         if (st) updateDuration(st);
         return new Date();
      });
   }, [updateDuration]);

   const stopTimer = useCallback(() => {
      setStartTime(st => {
         if (st) updateDuration(st);
      });
   }, [updateDuration]);

   const addWordCount = useCallback(count => setWords(w => w + count), []);
   const subtractDuration = useCallback(minutes => setDuration(d => d - minutes), []);

   // Update the pace
   useEffect(() => {
      if (duration <= 0) setPace(NaN);
      setPace(words / duration);
   }, [words, duration]);

   return {
      addWordCount,
      pace,
      startTimer,
      stopTimer,
      subtractDuration
   };
}

export default usePaceCoach;

import React, { useState } from "react";
// import InterviewerFace from "./InterviewerFace";
// import ListDetailsHeader from "./ListDetailsHeader";

function PreparednessArea({ data }) {
   // const hideFaceState = useState(true);
   const [hideQuestions, setHideQuestions] = useState(false);

   return (
      <div className="table-expand-area-item">
         <div>
            <strong>Questions</strong>
            &emsp;
            <small role="button" onClick={() => setHideQuestions(h => !h)}>
               {hideQuestions ? "(View)" : "(Hide)"}
            </small>
         </div>
         <p hidden={hideQuestions} className="text-section">
            {data.generatedQuestions}
         </p>
         {/* <hr />
         <ListDetailsHeader name="Video Interview" hiddenState={hideFaceState} />
         <InterviewerFace hidden={hideFaceState[0]} /> */}
      </div>
   );
}

export default PreparednessArea;

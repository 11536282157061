import React from "react";
import Chart from "react-apexcharts";

/**
 * @param {object} props
 * @param {{
 *    label: string
 *    target: number
 *    performance: number
 * }[]} props.stats
 */
function OverallChart(props) {
   // const [labels] = useState([
   //    "Sentiment",
   //    "Filler Words",
   //    "Pace",
   //    "Decisive",
   //    "Repetitive Language",
   //    "Inclusiveness",
   //    "Eye Contact",
   //    "Intonation",
   //    "Monologue"
   // ]);
   return (
      <Chart
         type="radar"
         series={[
            {
               name: "Target",
               data: props.stats.map(s => s.target),
               color: "#1DDAFF"
            },
            {
               name: "Your Performance",
               data: props.stats.map(s => s.performance),
               color: '#9B88FA'
            }
         ]}
         options={{
            labels: props.stats.map(s => s.label),
            fill: {
               opacity: 0.5
            },
            grid: {
               padding: {
                  top: -50,
                  bottom: -50,
                  right: -30,
                  left: -30
               }
            },
            stroke: {
               show: false
            },
            legend: {
               onItemClick: {
                  toggleDataSeries: false
               }
            },
            markers: {
               size: 4,
               fillOpacity: 0.01,
               strokeWidth: 0
            },
            xaxis: {
               labels: {
                  show: true,
                  style: {
                     colors: Array(props.stats.length).fill('black'),
                     fontSize: '12px'
                  }
               }
            },
            yaxis: {
               show: false,
               max: 100,
               tickAmount: 5
            },
            responsive: [
               // {
               //    breakpoint: 1200, // xl breakpoint
               //    options: {
               //       xaxis: {
               //          labels: {
               //             formatter: v => v.slice(0, 3)
               //             //trim: true
               //          }
               //       }
               //       //labels: ["Sen", "Fil", "Pac", "Dec", "Rep", "Inc", "Eye", "Int", "Mon"]
               //    }
               // },
               {
                  breakpoint: 768, // md breakpoint
                  options: {
                     xaxis: {
                        labels: {
                           show: false
                        }
                     }
                  }
               }
            ]
         }}
      />
   );
}

export default OverallChart;

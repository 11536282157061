import React, { useEffect, useCallback } from "react";
import { useState } from "react";
import axios from "axios";
import { Spinner, Button, Badge } from "react-bootstrap";
import RichTextEditor from "react-rte";
import ResultArea from "../components/ResultArea";
import useUserParam from "../hooks/useUserParam";
import config from "../config";
import useLanguageParam from "../hooks/useLanguageParam";

const messages = [
   "Analyzing",
   "Processing",
   "Crafting",
   "Generating",
   "Reviewing",
   "Testing",
   "Applying",
   "Organizing",
   "Validating",
   "Compiling",
   "Documenting",
   "Constructing",
   "Executing",
   "Querying",
   "Implementing",
   "Surveying"
];

function PreparednessGenerator() {
   const user = useUserParam();
   const lang = useLanguageParam();
   const [prompt, setPrompt] = useState(RichTextEditor.createEmptyValue());
   const [cleanPrompt, setCleanPrompt] = useState("");
   const [preparednessResult, setPreparednessResult] = useState("");
   const [working, setWorking] = useState(false);
   const [badgeColors, setBadgeColors] = useState({
      bg: "light",
      text: "dark"
   });

   const [msgi, setMsgi] = useState((Math.random() * messages.length) | 0);
   useEffect(() => {
      if (working) {
         const msgiTimerId = setInterval(
            () => setMsgi((Math.random() * messages.length) | 0),
            3000
         );
         return () => clearInterval(msgiTimerId);
      }
   }, [working]);

   useEffect(() => {
      if (cleanPrompt.length > 14000) setBadgeColors({ bg: "danger", text: "light" });
      else if (cleanPrompt.length > 13000) setBadgeColors({ bg: "warning", text: "dark" });
      else setBadgeColors({ bg: "light", text: "dark" });
   }, [cleanPrompt]);

   const cleanup = useCallback(
      str =>
         str
            .trim()
            // The input text area seems to love sprinkling in zero-width spaces
            .replace(/\u200b/g, "")
            // Lines with nothing but whitespace can have that whitespace trimmed
            // This also matches multiple newlines in a row
            .replace(/^\s+$/gm, ""),
      []
   );
   const generatePreparedness = useCallback((prompt, language, user) => {
      setWorking(true);
      axios
         .post(config.urls.preparedness, {
            language,
            prompt,
            user
         })
         .then(
            res => {
               setPreparednessResult(res.data.preparedness);
            },
            err => {
               console.warn("No response");
               console.error(err);
               const statusType = (err.response.status / 100) | 0;
               if (err.response.status === 403) {
                  setPreparednessResult(err.response.data);
               } else if (statusType === 4) {
                  setPreparednessResult("Unable to authenticate with generator server.");
               } else {
                  setPreparednessResult(
                     "We apologize, our servers are overloaded with requests. Please try again in a few minutes."
                  );
               }
            }
         )
         .then(() => setWorking(false));
   }, []);

   return (
      <div className="App">
         <div className="d-flex align-items-end justify-content-between" style={{ gap: "0.5rem" }}>
            <p className="generator-heading">Paste Job Details:</p>
            <div className="ml-auto mb-1 d-flex flex-wrap" style={{ gap: "0.25rem" }}>
               {/* <Button size="sm" onClick={() => setLang(l => (l + 1) % languages.length)}>
                  {languages[lang]}
               </Button> */}
               <Button
                  size="sm"
                  variant="warning"
                  onClick={() => {
                     setPrompt(RichTextEditor.createEmptyValue());
                     setPreparednessResult("");
                  }}
               >
                  Clear All
               </Button>
            </div>
         </div>
         <div style={{ position: "relative", flex: 1, overflowY: "auto" }}>
            <RichTextEditor
               rootStyle={{ height: "100%", overflowY: "auto", fontFamily: "sans-serif" }}
               toolbarStyle={{ display: "none" }}
               value={prompt}
               onChange={val => {
                  setPrompt(val);
                  setCleanPrompt(cleanup(val.toString("markdown")));
               }}
               editorStyle={{ height: "100%" }}
            />
            <Badge
               pill
               {...badgeColors}
               className="bottom-right-position"
               style={{
                  fontSize: "0.66rem"
               }}
            >
               {cleanPrompt.length} / 14000
            </Badge>
         </div>
         <div className="mx-auto my-3 px-2">
            <Button
               variant="primary"
               disabled={working || cleanPrompt.length < 10 || cleanPrompt.length > 14000}
               onClick={() => generatePreparedness(cleanPrompt, lang, user)}
            >
               {!working ? (
                  "Get Interview Preparation"
               ) : (
                  <>
                     {messages[msgi]} <Spinner size="sm" />
                  </>
               )}
            </Button>
         </div>
         <ResultArea label="Interview Questions:" value={preparednessResult} />
         {/* <div className="mx-auto my-3 px-2">
            <Button
               variant="primary"
               disabled={working || cleanPrompt.length < 10 || cleanPrompt.length > 14000}
               onClick={() => generatePreparedness(cleanPrompt, languages[lang], user)}
            >
               Regenerate
            </Button>
         </div> */}
      </div>
   );
}

export default PreparednessGenerator;

const version = "v2";

const config = {
   urls: {
      createInterview: `/api/${version}/interview`,
      generator: `/api/${version}/generate`,
      history: `/api/${version}/history`,
      preparedness: `/api/${version}/preparedness`,
      preparednessVideo: `/api/${version}/preparedness/video`
   }
};

export default config;

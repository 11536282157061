import React from "react";
import Generator from "./pages/generator";
import History from "./pages/history";

const v1Routes = [
   { path: "generator", element: <Generator /> },
   { path: "history", element: <History /> }
];

export default v1Routes;

import axios from "axios";
import { useRef, useState } from "react";
import config from "../config";

const RTCPeerConnection = (
   window.RTCPeerConnection ||
   window.webkitRTCPeerConnection ||
   window.mozRTCPeerConnection
).bind(window);

class DIdVideo {
   peerConnection;
   streamId;
   sessionId;
   sessionClientAnswer;

   statsIntervalId;
   videoIsPlaying;
   lastBytesReceived;

   user;
   prepId;
   videoRef;

   constructor({ user, prep, videoRef }) {
      this.user = user;
      this.prepId = prep;
      this.videoRef = videoRef;
   }

   getRef() {
      return this.videoRef;
   }
   getStreamData() {
      return {
         streamId: this.streamId,
         sessionId: this.sessionId
      };
   }

   async createConnection({ imageUrl }) {
      if (this.peerConnection && this.peerConnection.connectionState === "connected") {
         return;
      }

      this.stopAllStreams();
      this.closePC();

      const sessionResponse = await axios.post(config.urls.preparednessVideo + "/connect", {
         user: this.user,
         preparedness: this.prepId,
         image: imageUrl
      });

      const {
         streamId: newStreamId,
         offer,
         iceServers,
         sessionId: newSessionId
      } = sessionResponse.data;
      this.streamId = newStreamId;
      this.sessionId = newSessionId;

      try {
         this.sessionClientAnswer = await this.createPeerConnection(offer, iceServers);
      } catch (e) {
         console.log("error during streaming setup", e);
         this.stopAllStreams();
         this.closePC();
         return;
      }

      const sdpResponse = await axios.post(config.urls.preparednessVideo + "/sdp", {
         sessionId: this.sessionId,
         streamId: this.streamId,
         user: this.user,
         sessionClientAnswer: this.sessionClientAnswer
      });
      console.log("sdpResponse", sdpResponse);
   }

   async startStreaming() {
      // connectionState not supported in firefox
      if (
         this.peerConnection?.signalingState === "stable" ||
         this.peerConnection?.iceConnectionState === "connected"
      ) {
         const talkResponse = axios.post(config.urls.preparednessVideo + "/start", {
            streamId: this.streamId,
            sessionId: this.sessionId,
            user: this.user,
            preparedness: this.prepId
         });
         console.log("talkResponse", talkResponse);
      }
   }

   async stopStreaming() {
      await axios.delete(config.urls.preparednessVideo, {
         params: {
            user: this.user,
            streamId: this.streamId,
            sessionId: this.sessionId
         }
      });

      this.stopAllStreams();
      this.closePC();
   }
   //#region Event Handlers
   onIceGatheringStateChange() {
      console.log("onIceGatheringStateChange", this.peerConnection.iceGatheringState);
   }

   onIceCandidate(event) {
      console.log("onIceCandidate", event);
      console.log(this);
      if (event.candidate) {
         const { candidate, sdpMid, sdpMLineIndex } = event.candidate;

         axios.post(config.urls.preparednessVideo + "/candidate", {
            candidate: { candidate, sdpMid, sdpMLineIndex },
            streamId: this.streamId,
            sessionId: this.sessionId,
            user: this.user
         });
      }
   }

   onIceConnectionStateChange() {
      console.log("onIceConnectionStateChange", this.peerConnection.iceConnectionState);
      if (
         this.peerConnection.iceConnectionState === "failed" ||
         this.peerConnection.iceConnectionState === "closed"
      ) {
         this.stopAllStreams();
         this.closePC();
         if (this.failed) this.failed();
      } else if (this.ready) this.ready();
   }

   onConnectionStateChange() {
      // not supported in firefox
      // So they claim, but I use firefox and it appears to be working for me
      console.log("onConnectionStateChange", this.peerConnection.connectionState);
   }

   onSignalingStateChange() {
      console.log("onSignalingStateChange", this.peerConnection.signalingState);
   }

   onVideoStatusChange(videoIsPlaying, stream) {
      let status;
      if (videoIsPlaying) {
         status = "streaming";
         const remoteStream = stream;
         this.setVideoElement(remoteStream);
      }
      console.log("onVideoStatusChange", status);
   }

   onTrack(event) {
      /**
       * The following code is designed to provide information about wether currently there is data
       * that's being streamed - It does so by periodically looking for changes in total stream data size
       *
       * This information in our case is used in order to show idle video while no talk is streaming.
       */

      if (!event.track) return;

      const intervalId = setInterval(async () => {
         try {
            const stats = await this.peerConnection.getStats(event.track);
            stats.forEach(report => {
               if (report.type === "inbound-rtp" && report.mediaType === "video") {
                  const moreBytes = report.bytesReceived > this.lastBytesReceived;
                  const videoStatusChanged = this.videoIsPlaying !== moreBytes;

                  if (videoStatusChanged) {
                     this.videoIsPlaying = report.bytesReceived > this.lastBytesReceived;
                     this.onVideoStatusChange(this.videoIsPlaying, event.streams[0]);
                  }
                  this.lastBytesReceived = report.bytesReceived;
               }
            });
         } catch (err) {
            console.warn("Error fetching track stats");
            console.error(err);
            clearInterval(intervalId);
         }
      }, 500);
      console.log(intervalId);
      this.statsIntervalId = intervalId;
   }
   //#endregion

   async createPeerConnection(offer, iceServers) {
      if (!this.peerConnection) {
         this.peerConnection = new RTCPeerConnection({ iceServers });
         this.peerConnection.addEventListener(
            "icegatheringstatechange",
            this.onIceGatheringStateChange.bind(this),
            true
         );
         this.peerConnection.addEventListener("icecandidate", this.onIceCandidate.bind(this), true);
         this.peerConnection.addEventListener(
            "iceconnectionstatechange",
            this.onIceConnectionStateChange.bind(this),
            true
         );
         this.peerConnection.addEventListener(
            "connectionstatechange",
            this.onConnectionStateChange.bind(this),
            true
         );
         this.peerConnection.addEventListener(
            "signalingstatechange",
            this.onSignalingStateChange.bind(this),
            true
         );
         this.peerConnection.addEventListener("track", this.onTrack.bind(this), true);
      }

      await this.peerConnection.setRemoteDescription(offer);
      console.log("set remote sdp OK");

      const sessionClientAnswer = await this.peerConnection.createAnswer();
      console.log("create local sdp OK");

      await this.peerConnection.setLocalDescription(sessionClientAnswer);
      console.log("set local sdp OK");

      return sessionClientAnswer;
   }

   setVideoElement(stream) {
      if (!stream) return;
      const talkVideo = this.videoRef.current;
      talkVideo.srcObject = stream;
      talkVideo.loop = false;

      // safari hotfix
      if (talkVideo.paused) {
         talkVideo
            .play()
            .then(_ => {})
            .catch(e => {});
      }
   }

   stopAllStreams() {
      const talkVideo = this.videoRef.current;
      if (talkVideo?.srcObject) {
         console.log("stopping video streams");
         talkVideo.srcObject.getTracks().forEach(track => track.stop());
         talkVideo.srcObject = null;
      }
   }

   closePC(pc = this.peerConnection) {
      if (!pc) return;
      console.log("stopping peer connection");
      pc.close();
      pc.removeEventListener("icegatheringstatechange", this.onIceGatheringStateChange, true);
      pc.removeEventListener("icecandidate", this.onIceCandidate, true);
      pc.removeEventListener("iceconnectionstatechange", this.onIceConnectionStateChange, true);
      pc.removeEventListener("connectionstatechange", this.onConnectionStateChange, true);
      pc.removeEventListener("signalingstatechange", this.onSignalingStateChange, true);
      pc.removeEventListener("track", this.onTrack, true);
      clearInterval(this.statsIntervalId);
      console.log("stopped peer connection");
      if (pc === this.peerConnection) {
         this.peerConnection = null;
      }
   }
}

function useDIdVideo({ user, prep }) {
   const videoRef = useRef();
   const [videoObj] = useState(new DIdVideo({ user, prep, videoRef }));
   const [resultObj] = useState({
      connect: videoObj.createConnection.bind(videoObj),
      failed: callback => (videoObj.failed = callback),
      getStreamData: videoObj.getStreamData.bind(videoObj),
      start: videoObj.startStreaming.bind(videoObj),
      stop: videoObj.stopStreaming.bind(videoObj),
      ready: callback => (videoObj.ready = callback),
      ref: videoRef
   });
   return resultObj;
}

export default useDIdVideo;

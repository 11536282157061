import { useCallback } from "react";

const useDownloadDocx = function () {
   return useCallback(
      /**
       * @param {string} str Document content
       * @param {{
       *    company: string
       *    jobTitle: string
       *    type: string
       * }} meta To determine default document name
       */
      (str, meta) => {
      const preHtml =
         "<html " +
         "xmlns:o='urn:schemas-microsoft-com:office:office' " +
         "xmlns:w='urn:schemas-microsoft-com:office:word' " +
         "xmlns='http://www.w3.org/TR/REC-html40'>" +
         "<head><meta charset='utf-8'>" +
         "<style>body { font-family: arial; }</style>" +
         "</head><body>";
      const postHtml = "</body></html>";
      const html = preHtml + str + postHtml;

      // const blob = new Blob(["\ufeff", html], {
      //    type: "application/msword"
      // });
      const url = "data:application/vnd.ms-word;charset=utf-8," + encodeURIComponent(html);
      const filename = `${meta.company}-${meta.jobTitle}-${meta.type}.doc`;

      // const result = await HTMLtoDOCX(str);
      // console.log(result);
      // const blob = new Blob([result]);

      // // Download the file
      // const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = filename;
      // a.target = "_self";
      a.click();
      //window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
   }, []);
};

export default useDownloadDocx;

import React, { useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { Download } from "react-feather";
import Markdown from "react-markdown";
import remarkBreaks from "remark-breaks";
import useDocxDownloader from '../hooks/useDocxDownloader';
//import HTMLtoDOCX from "html-to-docx";
//import { Document } from "docx";

function HistoryDetails({ generation }) {
   const [hideLetter, setHideLetter] = useState(false);
   const [hidePosting, setHidePosting] = useState(true);
   const [hideResume, setHideResume] = useState(false);

   const letterRef = useRef();
   const resumeRef = useRef();

   const downloadDocx = useDocxDownloader();

   return (
      <div className="table-expand-area-item">
         <div>
            <strong>Job Posting</strong>
            &emsp;
            <small role="button" onClick={() => setHidePosting(h => !h)}>
               {hidePosting ? <Button size="sm">View</Button> : <Button size="sm">Hide</Button>}
            </small>
         </div>
         <p hidden={hidePosting} className="text-section">
            {generation.posting}
         </p>
         <hr />
         <div className="d-flex justify-content-between align-items-center">
            <div>
               <strong>Cover Letter</strong>
               &emsp;
               <small role="button" onClick={() => setHideLetter(h => !h)}>
                  {hideLetter ? <Button size="sm">View</Button> : <Button size="sm">Hide</Button>}
               </small>
            </div>
            <Button
               variant="success"
               onClick={() =>
                  downloadDocx(letterRef.current?.innerHTML, {
                     company: generation.company,
                     jobTitle: generation.jobTitle,
                     type: "coverletter"
                  })
               }
            >
               <Download size={23} />
               <span className="d-none d-sm-inline"> Download</span>
            </Button>
         </div>
         <div hidden={hideLetter} ref={letterRef} className="mt-4">
            <Markdown remarkPlugins={[remarkBreaks]}>{generation.generatedCoverLetter}</Markdown>
         </div>
         <hr />
         <div className="d-flex justify-content-between align-items-center">
            <div>
               <strong>Resume</strong>
               &emsp;
               <small role="button" onClick={() => setHideResume(h => !h)}>
                  {hideResume ? <Button size="sm">View</Button> : <Button size="sm">Hide</Button>}
               </small>
            </div>
            <Button
               variant="success"
               onClick={() =>
                  downloadDocx(resumeRef.current?.innerHTML, {
                     company: generation.company,
                     jobTitle: generation.jobTitle,
                     type: "resume"
                  })
               }
            >
               <Download size={23} />
               <span className="d-none d-sm-inline"> Download</span>
            </Button>
         </div>
         <div hidden={hideResume} ref={resumeRef} className="mt-4">
            <Markdown remarkPlugins={[remarkBreaks]}>{generation.generatedResume}</Markdown>
         </div>
      </div>
   );
}

export default HistoryDetails;

import React, { useEffect, useState } from "react";
import CoachCard from "./CoachCard";
import OverallChart from "./charts/Overall";
import { CheckCircleFill, XCircleFill } from "react-bootstrap-icons";
import PerformanceBar from "./PerformanceBar";
import hireChanceBg from "../../images/rainbowbg.png";

const CoachIcon = ({ pass }) =>
   pass ? <CheckCircleFill size={16} color="green" /> : <XCircleFill size={16} color="red" />;

const targets = {
   answers: 70,
   decisive: 70,
   filler: 80,
   inclusive: 60,
   pace: 50,
   repetitive: 40,
   sentiment: 75
};

const weights = {
   answers: 60,
   decisive: 10,
   filler: 5,
   inclusive: 10,
   pace: 5,
   repetitive: 3,
   sentiment: 7
};

/**
 * @param {object} props
 * @param {boolean} props.inactive
 * @param {number} props.pace Pace of speech, in words per minute
 * @param {number} props.answers Evaluated score for answers
 * @param {number} props.sentiment Evaluated score for sentiment
 * @param {number} props.decisive Evaluated score for decisive
 * @param {{ [key: string]: number }} props.fillerWords Key-Values for filler words and their counts
 * @param {number} props.fillerWordScore Evaluated score for filler words
 * @param {number} props.repetitiveLanguage Evaluated score for repetitive language
 * @param {number} props.inclusiveness Evaluated score for inclusiveness
 */
function Coach(props) {
   const [answerScore, setAnswerScore] = useState(20);
   const [decisiveScore, setDecisiveScore] = useState(20);
   const [fillerScore, setFillerScore] = useState(20);
   const [fillerWordsFiltered, setFillerWordsFiltered] = useState([]);
   const [hireChanceScore, setHireChanceScore] = useState(20);
   const [inclusiveScore, setInclusiveScore] = useState(20);
   const [paceScore, setPaceScore] = useState(20);
   const [repetitiveScore, setRepetitiveScore] = useState(20);
   const [sentimentScore, setSentimentScore] = useState(20);

   useEffect(() => {
      if (props.inactive) return;

      setAnswerScore(props.answers);
      setDecisiveScore(props.decisive);
      setFillerScore(props.fillerWordScore);
      setInclusiveScore(props.inclusiveness);
      setRepetitiveScore(props.repetitiveLanguage);
      setSentimentScore(props.sentiment);
   }, [
      props.inactive,
      props.answers,
      props.decisive,
      props.fillerWordScore,
      props.inclusiveness,
      props.repetitiveLanguage,
      props.sentiment
   ]);

   useEffect(() => {
      const keys = Object.keys(props.fillerWords).filter(k => props.fillerWords[k] >= 2);
      setFillerWordsFiltered(keys.map(k => ({ word: k, count: props.fillerWords[k].toFixed(0) })));
   }, [props.fillerWords]);

   // Convert wpm pace to score
   useEffect(() => {
      if (props.inactive) return;
      // Target 100 wpm, reduce score by 1 per 1wpm difference
      const diff = Math.abs(100 - props.pace);
      setPaceScore(Math.max((100 - diff) | 0, 0));
   }, [props.inactive, props.pace]);

   useEffect(() => {
      const weightSum =
         weights.answers +
         weights.decisive +
         weights.filler +
         weights.inclusive +
         weights.pace +
         weights.repetitive +
         weights.sentiment;
      setHireChanceScore(
         (answerScore * weights.answers +
            decisiveScore * weights.decisive +
            fillerScore * weights.filler +
            inclusiveScore * weights.inclusive +
            paceScore * weights.pace +
            repetitiveScore * weights.repetitive +
            sentimentScore * weights.sentiment) /
            weightSum
      );
   }, [
      answerScore,
      decisiveScore,
      fillerScore,
      inclusiveScore,
      paceScore,
      repetitiveScore,
      sentimentScore
   ]);
   return (
      <div className="coach-wrapper">
         <CoachCard
            title={
               <span>
                  Hire Chance<sup>TM</sup> Score
               </span>
            }
            style={{
               backgroundImage: `url(${hireChanceBg})`
            }}
            rpad
         >
            {/* <div className="d-flex flex-column justify-content-center flex-grow-1">
               <HireChanceChart />
            </div> */}
            {/* Answers worth 60% of this score on their own */}
            <PerformanceBar inactive={props.inactive} score={hireChanceScore} />
            <div>An evaluation of your responses indicating your probability of getting hired</div>
         </CoachCard>
         <CoachCard
            title="Answers"
            icon={!props.inactive ? <CoachIcon pass={answerScore > targets.answers} /> : null}
            rpad
         >
            <PerformanceBar inactive={props.inactive} score={answerScore} />
            <div>How meaningful were the answers you provided</div>
         </CoachCard>
         <CoachCard
            title="Sentiment"
            icon={!props.inactive ? <CoachIcon pass={sentimentScore > targets.sentiment} /> : null}
            rpad
         >
            <PerformanceBar inactive={props.inactive} score={sentimentScore} />
            <div>Keeping a positive tone while providing your answers</div>
         </CoachCard>
         <CoachCard
            title="Pace"
            icon={!props.inactive ? <CoachIcon pass={paceScore > targets.pace} /> : null}
            rpad
         >
            <PerformanceBar inactive={props.inactive} score={paceScore} />
            <div>Varying pace of speech to keep people engaged</div>
         </CoachCard>
         <CoachCard title="Overall" width={2} height={2}>
            <OverallChart
               stats={[
                  {
                     label: "Answers",
                     target: targets.answers,
                     performance: answerScore
                  },
                  {
                     label: "Sentiment",
                     target: targets.sentiment,
                     performance: sentimentScore
                  },
                  {
                     label: "Filler Words",
                     target: targets.filler,
                     performance: fillerScore
                  },
                  {
                     label: "Pace",
                     target: targets.pace,
                     performance: paceScore
                  },
                  {
                     label: "Decisiveness",
                     target: targets.decisive,
                     performance: decisiveScore
                  },
                  {
                     label: "Repetitive Language",
                     target: targets.repetitive,
                     performance: repetitiveScore
                  },
                  {
                     label: "Inclusiveness",
                     target: targets.inclusive,
                     performance: inclusiveScore
                  }
                  // {
                  //    label: "Intonation",
                  //    target: 60,
                  //    performance: 66
                  // }
               ]}
            />
         </CoachCard>
         <CoachCard
            title="Filler Words"
            icon={!props.inactive ? <CoachIcon pass={fillerScore > targets.filler} /> : null}
            rpad
            width={2}
         >
            <div className="d-flex gap-3">
               <div className="d-flex flex-wrap gap-2 flex-grow-1 flex-basis-25 align-items-start mt-2 mb-auto">
                  {fillerWordsFiltered.length > 0 ? (
                     fillerWordsFiltered.map(fw => (
                        <div key={fw.word} className="coach-filler-word">
                           {fw.word} ({fw.count})
                        </div>
                     ))
                  ) : (
                     <div className="coach-filler-word">No filler words</div>
                  )}
               </div>
               <div className="flex-grow-1 flex-basis-25">
                  <PerformanceBar inactive={props.inactive} score={fillerScore} />
                  <div>Reduce the use of filler words to appear more confident</div>
               </div>
            </div>
         </CoachCard>
         <CoachCard
            title="Decisiveness"
            icon={!props.inactive ? <CoachIcon pass={decisiveScore > targets.decisive} /> : null}
            rpad
         >
            <PerformanceBar inactive={props.inactive} score={decisiveScore} />
            <div>Ability to express clear thought and good understanding of what is important</div>
         </CoachCard>
         <CoachCard
            title="Repetitive Language"
            icon={
               !props.inactive ? <CoachIcon pass={repetitiveScore > targets.repetitive} /> : null
            }
            rpad
         >
            <PerformanceBar inactive={props.inactive} score={repetitiveScore} />
            <div>Help people stay engaged and focused by avoiding repetitive language</div>
         </CoachCard>
         <CoachCard
            title="Inclusiveness"
            icon={!props.inactive ? <CoachIcon pass={inclusiveScore > targets.inclusive} /> : null}
            rpad
         >
            <PerformanceBar inactive={props.inactive} score={inclusiveScore} />
            <div>Using language which is not offensive towards people</div>
         </CoachCard>
      </div>
   );
}

export default Coach;

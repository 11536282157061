import React, { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import {
   ChevronDoubleLeft,
   ChevronDoubleRight,
   ChevronLeft,
   ChevronRight
} from "react-bootstrap-icons";

/**
 * @param {*[]} list
 * @param {number} itemsPerPage
 */
function usePagination(list, itemsPerPage = 10) {
   const [maxPage, setMaxPage] = useState(0);
   const [page, setPage] = useState(0);
   const [subset, setSubset] = useState([]);

   const next = useCallback(() => setPage(p => Math.min(p + 1, maxPage)), [maxPage]);
   const prev = useCallback(() => setPage(p => Math.max(p - 1, 0)), []);
   const last = useCallback(() => setPage(maxPage), [maxPage]);
   const setPageSafe = useCallback(n => setPage(Math.max(Math.min(n, maxPage), 0)), [maxPage]);

   useEffect(() => setPage(p => Math.min(p, maxPage)), [maxPage]);
   useEffect(() => setMaxPage((list.length / itemsPerPage) | 0), [list, itemsPerPage]);
   useEffect(
      () => setSubset(list.slice(page * itemsPerPage, (page + 1) * itemsPerPage)),
      [page, list, itemsPerPage]
   );

   function Controller() {
      return (
         <div className="d-flex gap-3 justify-content-center align-items-center">
            <Button variant="outline-secondary" onClick={() => setPage(0)} disabled={page === 0}>
               <ChevronDoubleLeft className="round-icon" />
            </Button>
            <Button variant="outline-secondary" onClick={prev} disabled={page === 0}>
               <ChevronLeft className="round-icon" />
            </Button>
            <div className="px-3">{page + 1}</div>
            <Button variant="outline-secondary" onClick={next} disabled={page === maxPage}>
               <ChevronRight className="round-icon" />
            </Button>
            <Button variant="outline-secondary" onClick={last} disabled={page === maxPage}>
               <ChevronDoubleRight className="round-icon" />
            </Button>
         </div>
      );
   }

   return {
      next,
      prev,
      last,
      subset,
      page,
      setPage: setPageSafe,
      maxPage,
      Controller
   };
}

export default usePagination;

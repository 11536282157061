import React, { useEffect, useState } from "react";
import axios from "axios";
import useUserParam from "../hooks/useUserParam";
import { Table } from "react-bootstrap";
import config from "../config";
import HistoryDetails from "../components/HistoryDetails";
import { ChevronDown, ChevronUp } from "react-feather";
import PrepareButton from "../components/PrepareButton";

function History() {
   const user = useUserParam();

   const [historyData, setHistoryData] = useState([]);
   const [expanded, setExpanded] = useState();

   useEffect(() => {
      axios
         .get(config.urls.history, {
            params: {
               user
            }
         })
         .then(
            res => {
               const data = res.data.sort((a, b) => new Date(b.created) - new Date(a.created));
               console.log(data);
               setHistoryData(data);
            },
            err => {
               console.warn("Failed to get history");
               console.error(err);
            }
         );
   }, [user]);

   return (
      <Table className="w-100 h-100" borderless>
         <colgroup>
            <col
               span="1"
               style={{
                  width: "4%"
               }}
            />
            <col
               span="2"
               style={{
                  width: "28%"
               }}
            />
            <col
               span="1"
               style={{
                  width: "16%"
               }}
            />
            <col
               span="1"
               style={{
                  width: "20%"
               }}
            />
         </colgroup>
         <thead>
            <tr>
               <th>#</th>
               <th>Company</th>
               <th>Job Title</th>
               <th>Date</th>
               <th></th>
            </tr>
         </thead>
         <tbody>
            {historyData.map(row => {
               const created = new Date(row.created);
               return (
                  <React.Fragment key={row.id}>
                     <tr style={{ borderTop: "2px solid whitesmoke" }}>
                        <td data-col="ID#">{row.id}</td>
                        <td data-col="Company">{row.company || "\u00a0"}</td>
                        <td data-col="Job Title">{row.jobTitle || "\u00a0"}</td>
                        <td data-col="Time">
                           {created.toLocaleDateString("default", {
                              day: "numeric",
                              month: "numeric",
                              year: "numeric"
                           })}
                           <br />
                           {created.toLocaleTimeString("default", {
                              hour: "numeric",
                              minute: "2-digit"
                           })}
                        </td>
                        <td>
                           <div className="horizontal-button-list">
                              {row.id !== expanded ? (
                                 <ChevronDown role="button" onClick={() => setExpanded(row.id)} />
                              ) : (
                                 <ChevronUp role="button" onClick={() => setExpanded()} />
                              )}
                              <PrepareButton history={row} />
                           </div>
                        </td>
                        <td></td>
                     </tr>
                     <tr hidden={row.id !== expanded}>
                        <td></td>
                        <td colSpan={4} className="pb-4">
                           <HistoryDetails generation={row} />
                        </td>
                        <td></td>
                     </tr>
                  </React.Fragment>
               );
            })}
         </tbody>
      </Table>
   );
}

export default History;

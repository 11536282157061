import React from "react";

/**
 * @param {object} props
 * @param {JSX.Element} props.children
 * @param {number} [props.height]
 * @param {JSX.Element} [props.icon]
 * @param {boolean} props.rpad
 * @param {object} [props.style]
 * @param {string | JSX.Element} props.title
 * @param {number} [props.width]
 */
function CoachCard(props) {
   const style = props.style || {};
   return (
      <div
         className="coach-card"
         style={{
            gridColumnEnd: `span ${props.width || 1}`,
            gridRowEnd: `span ${props.height || 1}`,
            ...style
         }}
      >
         <div className="coach-title">
            {props.title} {props.icon}
         </div>
         <div className={`coach-content ${props.rpad ? "rpad" : ""}`}>{props.children}</div>
      </div>
   );
}

export default CoachCard;

import v2Routes from "./v2";
import v3Routes from "./v3";
import v4Routes from "./v4"

const versionConfig = [
   { path: "/v2", children: v2Routes },
   { path: "/v3", children: v3Routes },
   { path: "/v4", children: v4Routes }

];

export default versionConfig;

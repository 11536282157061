import React, { useState } from "react";
import { Button } from "react-bootstrap";
import AnswerButtonSection from "./AnswerButtonSection";

function PreparednessArea({ data, onGenerateAnswers }) {
   const [hideQuestions, setHideQuestions] = useState(false);

   return (
      <div className="table-expand-area-item">
         <div>
            <strong>Questions</strong>
            &emsp;
            <Button size="sm" onClick={() => setHideQuestions(h => !h)}>
               {hideQuestions ? "View" : "Hide"}
            </Button>
         </div>
         <p hidden={hideQuestions} className="text-section">
            {data.generatedQuestions}
         </p>
         <AnswerButtonSection answerContent={data.generatedAnswers} onClick={onGenerateAnswers} />
      </div>
   );
}

export default PreparednessArea;

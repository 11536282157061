import React, { useEffect, useState } from "react";
import useUserParam from "../hooks/useUserParam";
import axios from "axios";
import config from "../config";
import { Button, FormControl, Table } from "react-bootstrap";
import usePagination from "../hooks/usePagination";
import { TelephoneFill, X } from "react-bootstrap-icons";

function Preparedness() {
   const user = useUserParam();

   const [filter, setFilter] = useState("");
   const [filteredList, setFilteredList] = useState([]);
   const [historyDataRaw, setHistoryDataRaw] = useState([]);
   const [userInfo, setUserInfo] = useState({});
   const [userName, setUserName] = useState("");
   const { subset: historyData, Controller } = usePagination(filteredList);

   useEffect(() => {
      axios.get(config.urls.preparedness, { params: { user } }).then(
         res => {
            const data = res.data.list.sort((a, b) => new Date(b.created) - new Date(a.created));
            console.log(data);
            setHistoryDataRaw(data);
            setUserInfo(res.data.user);
         },
         err => {
            console.warn("Failed to get preparedness history");
            console.error(err);
         }
      );
   }, [user]);
   useEffect(() => {
      const source = [userInfo.firstName, userInfo.lastName].filter(v => v);
      setUserName(source.join(" "));
   }, [userInfo]);

   useEffect(() => {
      const sublist = historyDataRaw.filter(
         r =>
            r.company?.toLowerCase().includes(filter) || r.jobTitle?.toLowerCase().includes(filter)
      );
      setFilteredList(sublist);
   }, [filter, historyDataRaw]);

   return (
      <div>
         <div className="d-flex p-2 align-items-center">
            <FormControl
               value={filter}
               placeholder="Search Company or Job Title"
               onChange={e => setFilter(e.target.value.toLowerCase())}
            />
            <X
               size={25}
               className={`cursor-pointer ${!filter ? "d-none" : ""}`}
               style={{ position: "absolute", right: "1rem" }}
               onClick={() => setFilter("")}
            />
         </div>
         <Table className="w-100 h-100" borderless>
            <colgroup>
               <col
                  span="1"
                  style={{
                     width: "4%"
                  }}
               />
               <col
                  span="2"
                  style={{
                     width: "30%"
                  }}
               />
               <col
                  span="1"
                  style={{
                     width: "16%"
                  }}
               />
               <col
                  span="1"
                  style={{
                     width: "16%"
                  }}
               />
            </colgroup>
            <thead>
               <tr>
                  <th>#</th>
                  <th>Company</th>
                  <th>Job Title</th>
                  <th>Date</th>
                  <th></th>
               </tr>
            </thead>
            <tbody>
               {historyData.map(row => {
                  const created = new Date(row.created);
                  return (
                     <React.Fragment key={row.id}>
                        <tr style={{ borderTop: "2px solid whitesmoke" }}>
                           <td data-col="ID#">{row.id}</td>
                           <td data-col="Company">{row.company || "\u00a0"}</td>
                           <td data-col="Job Title">{row.jobTitle || "\u00a0"}</td>
                           <td data-col="Time">
                              {created.toLocaleDateString("default", {
                                 day: "numeric",
                                 month: "numeric",
                                 year: "numeric"
                              })}
                              <br />
                              {created.toLocaleTimeString("default", {
                                 hour: "numeric",
                                 minute: "2-digit"
                              })}
                           </td>
                           <td>
                              <Button
                                 className="mt-1 list-button"
                                 variant="success"
                                 onClick={() => {
                                    const loc =
                                       process.env.REACT_APP_INTERNAL_URL +
                                       config.urls.preparednessPopup +
                                       `?u=${user}` +
                                       `&id=${row.id}` +
                                       `&un=${userName}` +
                                       `&c=${row.company}` +
                                       `&jt=${row.jobTitle}`;
                                    const interviewWindow = window.open(
                                       loc,
                                       "interview_window"
                                       //"popup,innerWidth=1180,innerHeight=940"
                                    );
                                    interviewWindow.addEventListener("message", e => {
                                       if (e.origin !== process.env.REACT_APP_INTERNAL_URL) return;
                                       if (e.data === "readyForDetails") {
                                          console.log("Post message");
                                          interviewWindow.postMessage(
                                             {
                                                company: row.company,
                                                jobTitle: row.jobTitle,
                                                userName
                                             },
                                             process.env.REACT_APP_INTERNAL_URL
                                          );
                                       }
                                    });
                                 }}
                              >
                                 <TelephoneFill size={16} /> Start Interview Practice
                              </Button>
                           </td>
                           <td></td>
                        </tr>
                     </React.Fragment>
                  );
               })}
            </tbody>
         </Table>
         <Controller />
      </div>
   );
}

export default Preparedness;

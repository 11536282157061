import React, { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import loadingAnim from "../images/call-earphone-telephone.gif";
import { Button } from "react-bootstrap";
import {
   BadgeCc,
   BadgeCcFill,
   CameraVideoFill,
   GearFill,
   MicFill,
   TelephoneFill,
   TelephoneXFill
} from "react-bootstrap-icons";
import { Mic, MicOff } from "react-feather";
import config from "../config";
import {
   SpeechConfig,
   AudioConfig,
   SpeechRecognizer,
   ResultReason,
   PropertyId,
   OutputFormat
} from "microsoft-cognitiveservices-speech-sdk";
import useUserParam from "../hooks/useUserParam";
import useDIdVideo from "../hooks/useRtcVideo";
import { useSearchParams } from "react-router-dom";
import ringAudio from "../images/ring-default.mp3";
import usePaceCoach from "../hooks/usePaceCoach";
import Coach from "../components/coach/Coach";

const accessColors = {
   Allowed: "#00C851",
   Pending: "grey",
   Blocked: "red"
};

const personas = {
   Lisa: {
      url: "/interviewers/lisa_2.png",
      name: "Lisa Peterson",
      voice: {
         id: "en-US-JennyNeural",
         style: "Friendly"
      }
   },
   Martin: {
      url: "/interviewers/martin_square-fill-updated.jpg",
      name: "Martin Jones",
      voice: {
         id: "en-US-JasonNeural",
         style: "Friendly"
      }
   }
};

function Interview() {
   const user = useUserParam();
   const searchParams = useSearchParams()[0];
   const preparednessId = searchParams.get("id");
   const didVideo = useDIdVideo({ user, prep: preparednessId });
   const { addWordCount, startTimer, stopTimer, pace } = usePaceCoach();

   const [audioTimeoutDelay, setAudioTimeoutDelay] = useState(3);
   const [audioState, setAudioState] = useState("Pending");
   const [buttonSubmit, setButtonSubmit] = useState(false);
   const [captionLabel, setCaptionLabel] = useState("");
   const [captionText, setCaptionText] = useState("");
   const [coachScores, setCoachScores] = useState({
      count: 0,
      answers: 0,
      sentiment: 0,
      pace: 0,
      decisive: 0,
      fillerWords: 0,
      repetitiveLanguage: 0,
      inclusiveness: 0
   });
   const [collectedSpeech, setCollectedSpeech] = useState("");
   const [company, setCompany] = useState(searchParams.get("c") || "{Company Name}");
   const [currentTime, setCurrentTime] = useState(new Date());
   const [fillerWordCounts, setFillerWordCounts] = useState({});
   const [hideConnectingOverlay, setHideConnectingOverlay] = useState(false);
   const [ignoreMicDuringAI, setIgnoreMicDuringAI] = useState(true);
   const [inProgressSpeech, setInProgressSpeech] = useState("");
   const [interviewDuration, setInterviewDuration] = useState(new Date(0));
   const [interviewStarted, setInterviewStarted] = useState(false);
   const [interviewStartTime, setInterviewStartTime] = useState();
   const [jobTitle, setJobTitle] = useState(searchParams.get("jt") || "{Job Title}");
   const [lastQuestion, setLastQuestion] = useState("");
   const [messageHistory, setMessageHistory] = useState([]);
   const [pauseRecognition, setPauseRecognition] = useState(true);
   const [personaSelection, setPersonaSelection] = useState("Martin");
   const [preserveCoachStats, setPreserveCoachStats] = useState(false);
   const [showAICaptions, setShowAICaptions] = useState(true);
   const [showMessageHistory, setShowMessageHistory] = useState(true);
   const [showPauseButton, setShowPauseButton] = useState(false);
   const [showSettings, setShowSettings] = useState(false);
   const [showUserCaptions, setShowUserCaptions] = useState(true);
   const [speechRecognizer, setSpeechRecognizer] = useState();
   const [speechRegion, setSpeechRegion] = useState();
   const [speechToken, setSpeechToken] = useState();
   const [streamState, setStreamState] = useState("Disconnected");
   const [userName, setUserName] = useState(searchParams.get("un") || "User's Name");
   const [videoState, setVideoState] = useState("Pending");

   const ringerRef = useRef();
   const webcamRef = useRef();

   useEffect(() => console.warn("Pace"), [addWordCount, startTimer, stopTimer]);

   const updateAiCaptions = useCallback(content => {
      setTimeout(() => {
         setCaptionLabel("");
         setCaptionText("");
         setLastQuestion(content);
      }, 1500);
   }, []);

   const startSpeechRecognizer = useCallback(
      (token = speechToken, region = speechRegion) => {
         if (!token || !region) return console.log("Speech token missing");

         console.log("Create speech config");
         const speechConfig = SpeechConfig.fromAuthorizationToken(token, region);
         speechConfig.outputFormat = OutputFormat.Detailed;
         speechConfig.setProperty(PropertyId.SpeechServiceResponse_PostProcessingOption, "default");
         const audioConfig = AudioConfig.fromDefaultMicrophoneInput();
         const recognizer = new SpeechRecognizer(speechConfig, audioConfig);

         recognizer.recognizing = (s, e) => {
            setInProgressSpeech(e.result.text);
         };
         recognizer.recognized = (s, e) => {
            if (e.result.reason === ResultReason.RecognizedSpeech) {
               startTimer();
               setInProgressSpeech("");
               setCollectedSpeech(cs => cs + " " + e.result.text);
               const spaces = e.result.text.match(/ /g);
               const words = spaces ? spaces.length + 1 : 0;
               addWordCount(words);
            } else if (e.result.reason === ResultReason.NoMatch)
               console.warn("NoMatch: Speech could not be recognized");
         };
         recognizer.canceled = (s, e) => {
            console.log(`Canceled: ${e.reason}`);
            recognizer.stopContinuousRecognitionAsync();
         };
         recognizer.sessionStopped = (s, e) => {
            console.log("Session Stopped");
            recognizer.stopContinuousRecognitionAsync();
         };

         setInterviewStarted(true);
         setSpeechRecognizer(recognizer);
         setInterviewStartTime(new Date());
      },
      [speechToken, speechRegion, addWordCount, startTimer]
   );

   const createConnection = useCallback(() => {
      setStreamState("Loading");
      if (ringerRef.current) ringerRef.current.play();
      didVideo
         .connect({
            imageUrl: process.env.REACT_APP_PUBLIC_URL + personas[personaSelection].url
         })
         .then(
            () => {
               axios
                  .get(config.urls.getSpeechToken, {
                     params: { user }
                  })
                  .then(
                     res => {
                        console.log(res.data);
                        const speechInfo = {
                           token: res.data.token,
                           region: res.data.region
                        };
                        setSpeechToken(speechInfo.token);
                        setSpeechRegion(speechInfo.region);
                        setTimeout(() => {
                           if (ringerRef.current) ringerRef.current.pause();
                           axios
                              .post(config.urls.preparednessStart, {
                                 user,
                                 preparedness: preparednessId,
                                 ...didVideo.getStreamData(),
                                 voice: personas[personaSelection].voice
                              })
                              .then(
                                 res => {
                                    const { messages, duration } = res.data;
                                    setMessageHistory(messages);
                                    startSpeechRecognizer(speechInfo.token, speechInfo.region);
                                    updateAiCaptions(messages[messages.length - 1].content);
                                    if (ignoreMicDuringAI) {
                                       // setPauseRecognition(true);
                                       setTimeout(
                                          () => setPauseRecognition(false),
                                          duration * 1000
                                       );
                                    }
                                    setHideConnectingOverlay(true);
                                 },
                                 err => {
                                    console.warn("Failed to generate greeting");
                                    console.error(err);
                                    setCaptionText("Connecting...");
                                    setShowAICaptions(true);
                                    setTimeout(() => {
                                       axios
                                          .post(config.urls.preparednessStart, {
                                             user,
                                             preparedness: preparednessId,
                                             ...didVideo.getStreamData(),
                                             voice: personas[personaSelection].voice
                                          })
                                          .then(
                                             res => {
                                                const { messages, duration } = res.data;
                                                setMessageHistory(messages);
                                                startSpeechRecognizer(
                                                   speechInfo.token,
                                                   speechInfo.region
                                                );
                                                updateAiCaptions(
                                                   messages[messages.length - 1].content
                                                );
                                                if (ignoreMicDuringAI) {
                                                   // setPauseRecognition(true);
                                                   setTimeout(
                                                      () => setPauseRecognition(false),
                                                      duration * 1000
                                                   );
                                                }
                                                setHideConnectingOverlay(true);
                                             },
                                             err => {
                                                console.warn("Failed to generate greeting");
                                                console.error(err);
                                                setCaptionText(
                                                   "Failed to generate greeting. Please close this window and try again in a few minutes."
                                                );
                                                setShowAICaptions(true);
                                             }
                                          );
                                    }, 6000);
                                 }
                              );
                        }, 2500);
                     },
                     err => {
                        console.warn("Couldn't get speech token");
                        console.error(err);
                     }
                  );
            },
            err => {
               console.warn("Couldn't connect stream");
               console.error(err.response.data);
               const errorMessage =
               err.response && err.response.status === 403
                  ? err.response.data
                  : "Failed to connect stream. Please close this window and try again in a few minutes.";
            setCaptionText(errorMessage);
               // setCaptionText(
               //    "Failed to connect stream. Please close this window and try again in a few minutes."
               // );
            }
         );
   }, [
      didVideo,
      startSpeechRecognizer,
      preparednessId,
      user,
      personaSelection,
      updateAiCaptions,
      ignoreMicDuringAI
   ]);

   const stopConnection = useCallback(() => {
      didVideo.stop();
      setStreamState("Finished");
      setInterviewStarted(false);
      setInterviewStartTime();
   }, [didVideo]);

   const updateCoachValues = useCallback(
      (question, answer) => {
         axios
            .post(config.urls.coachUpdate, {
               user,
               question,
               answer
            })
            .then(
               res => {
                  console.log(res.data);
                  setCoachScores(cs => {
                     const update = (old, add, oldCount) => (old * oldCount + add) / (oldCount + 1);
                     cs.answers = update(cs.answers, res.data.answerScore, cs.count);
                     cs.sentiment = update(cs.sentiment, res.data.sentimentScore, cs.count);
                     cs.pace = pace;
                     cs.decisive = update(cs.decisive, res.data.decisiveScore, cs.count);
                     cs.fillerWords = update(cs.fillerWords, res.data.fillerScore, cs.count);
                     cs.repetitiveLanguage = update(
                        cs.repetitiveLanguage,
                        res.data.repetitiveScore,
                        cs.count
                     );
                     cs.inclusiveness = update(
                        cs.inclusiveness,
                        res.data.inclusivenessScore,
                        cs.count
                     );
                     cs.count++;
                     console.log(cs);
                     return cs;
                  });
                  setFillerWordCounts(fwc => {
                     const combined = {};
                     const addKey = (k, arr) => (combined[k] = (combined[k] || 0) + arr[k]);
                     Object.keys(fwc).forEach(fw => addKey(fw, fwc));
                     Object.keys(res.data.fillerCounts).forEach(fw =>
                        addKey(fw, res.data.fillerCounts)
                     );
                     return combined;
                  });
               },
               err => {
                  console.warn("Failed to update coach values");
                  console.error(err);
               }
            );
      },
      [user, pace]
   );

   const submitAnswer = useCallback(() => {
      const userAnswer = { role: "user", content: collectedSpeech.trim() };
      console.log(userAnswer);
      if (userAnswer.content.length < 10) return;
      axios
         .post(config.urls.preparednessChat, {
            user,
            messages: messageHistory.concat([userAnswer]),
            streamData: didVideo.getStreamData(),
            voice: personas[personaSelection].voice
         })
         .then(
            res => {
               // Ask for updates regarding speech coach
               updateCoachValues(lastQuestion, userAnswer.content);
               // Display new values
               updateAiCaptions(res.data.content);
               setMessageHistory(hist =>
                  hist.concat([userAnswer, { role: "assistant", content: res.data.content }])
               );
               if (res.status === 250) console.warn("Failed to generate video");
               if (ignoreMicDuringAI && res.data.duration) {
                  setPauseRecognition(true);
                  setTimeout(() => {
                     setPauseRecognition(false);
                     startTimer();
                  }, (res.data.duration + 1.5) * 1000);
               }
            },
            err => {
               console.warn("Couldn't get chat response from server");
               console.error(err);
            }
         );

      setCollectedSpeech("");
      stopTimer();
      setTimeout(() => {
         setCaptionLabel(personas[personaSelection].name);
         setCaptionText("I'm gathering thoughts, please wait.");
      }, 100);
   }, [
      messageHistory,
      collectedSpeech,
      didVideo,
      user,
      ignoreMicDuringAI,
      lastQuestion,
      startTimer,
      stopTimer,
      personaSelection,
      updateAiCaptions,
      updateCoachValues
   ]);

   // Once the interview has started, set the stats to persist after it's done
   useEffect(() => {
      if (interviewStarted) setPreserveCoachStats(true);
   }, [interviewStarted]);
   // Each second, update the current time
   useEffect(() => {
      const timeInterval = setInterval(() => setCurrentTime(new Date()), 1000);
      return () => clearInterval(timeInterval);
   }, []);
   // Update the interview duration
   useEffect(() => {
      if (!interviewStartTime) setInterviewDuration(new Date(0));
      else {
         const difference = currentTime.getTime() - interviewStartTime.getTime();
         setInterviewDuration(new Date(difference));
      }
   }, [currentTime, interviewStartTime]);
   // Create an event listener for state items from parent window
   useEffect(() => {
      window.addEventListener("message", e => {
         if (e.origin !== process.env.REACT_APP_INTERNAL_URL) return;
         if (e.data.company) setCompany(e.data.company);
         if (e.data.jobTitle) setJobTitle(e.data.jobTitle);
         if (e.data.userName) setUserName(e.data.userName);
      });
      window.top.postMessage("readyForDetails", process.env.REACT_APP_INTERNAL_URL);
   }, []);
   // Set the caption text whenever the recognizer identifies more content
   useEffect(() => {
      const captions = (collectedSpeech + " " + inProgressSpeech).trim();
      setCaptionText(captions);
      if (captions.length > 0) setCaptionLabel(userName + ":");
   }, [collectedSpeech, inProgressSpeech, userName]);
   // Send the collected text to the server after a period of inactivity
   useEffect(() => {
      // Make sure the timer should be activated in the first place
      if (collectedSpeech.length < 10 || !interviewStarted || pauseRecognition) return;
      console.log("Start timer for speech submit");

      const timeoutId = setTimeout(submitAnswer, audioTimeoutDelay * 1000);
      return () => clearTimeout(timeoutId);
   }, [
      collectedSpeech,
      inProgressSpeech,
      audioTimeoutDelay,
      interviewStarted,
      submitAnswer,
      pauseRecognition
   ]);
   // Get the camera and display it on screen
   useEffect(() => {
      console.log("Get user media");
      navigator.mediaDevices.getUserMedia({ video: true, audio: false }).then(
         stream => {
            const video = webcamRef.current;
            if (video) {
               video.srcObject = stream;
               video.play();
               setVideoState("Allowed");
            }
         },
         err => {
            console.log(err);
            setVideoState("Blocked");
         }
      );
   }, []);
   // Get microphone permissions
   useEffect(() => {
      console.log("Get user audio");
      navigator.mediaDevices.getUserMedia({ video: false, audio: true }).then(
         audioStream => {
            console.log(audioStream);
            setAudioState("Allowed");
         },
         err => {
            console.log(err);
            setAudioState("Blocked");
         }
      );
   }, []);
   // Set the stream ready listener
   useEffect(() => {
      didVideo.ready(() => setStreamState("Connected"));
      didVideo.failed(() => setStreamState("Failed"));
   }, [didVideo]);
   // If the stream fails, show a message
   useEffect(() => {
      if (streamState === "Failed") {
         setShowAICaptions(true);
         setCaptionText(
            "Stream connection failed. Please wait a few minutes then close and reopen this window"
         );
         stopConnection();
      }
   }, [streamState, stopConnection]);
   // Stop/Start recognition when mic is disabled/enabled
   useEffect(() => {
      console.log(speechRecognizer);
      if (!speechRecognizer) return;

      console.log(pauseRecognition);
      console.log(interviewStarted);

      if (pauseRecognition || !interviewStarted) {
         speechRecognizer.stopContinuousRecognitionAsync();
         stopTimer();
      } else {
         setCollectedSpeech("");
         speechRecognizer.startContinuousRecognitionAsync();
         console.log("start recognition");
         startTimer();
      }
   }, [speechRecognizer, pauseRecognition, interviewStarted, startTimer, stopTimer]);
   return (
      <div
         style={{
            height: "100vh"
            // backgroundColor: "whitesmoke"
         }}
      >
         <div className="interview-wrapper">
            <div
               className="interview-row"
               // style={{
               //    // height: "3rem"
               //    // padding: "0 1.75rem"
               // }}
            >
               <div className="flex-fill d-flex justify-content-between align-items-center gap-3">
                  <div
                     style={{
                        //aspectRatio: "141 / 18",
                        flexBasis: "140px"
                     }}
                  >
                     <img src="/RessoAI-logo.svg" alt="Logo" />
                  </div>
                  <div className="align-middle interview-head-text text-secondary">
                     Interview Practice
                  </div>
               </div>
               {/* <Button
                  className="fw-bold"
                  size="sm"
                  style={{
                     color: "white",
                     padding: "0.5rem 1rem",
                     backgroundColor: "#2E3191",
                     borderColor: "#2E3191",
                     borderRadius: "7px"
                  }}
               >
                  <Share2 size={15} /> &nbsp; Share interview
               </Button> */}
            </div>
            <div className="interview-row">
               <div className="video-col flex-basis-75 d-flex flex-column">
                  {streamState === "Disconnected" || streamState === "Finished" ? (
                     <div
                        className="interview-video flex-center square-bottom"
                        style={{
                           backgroundColor: "#1F1F1F",
                           borderBottom: 0,
                           color: "white"
                        }}
                     >
                        <div className="flex-center">
                           <div className="primary-text text-center">Interview for: {company}</div>
                           <div className="text-center">Position: {jobTitle}</div>
                        </div>
                     </div>
                  ) : (
                     <div
                        className="interview-video square-bottom"
                        style={{
                           backgroundImage: `url("${personas[personaSelection].url}")`,
                           backgroundPosition: "center",
                           backgroundSize: "cover",
                           borderBottom: 0,
                           position: "relative"
                        }}
                     >
                        <div
                           className={`gap-3 overlay-text pt-3 px-5 d-flex flex-column align-items-center ${
                              hideConnectingOverlay ? "d-none" : ""
                           }`}
                           style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)"
                           }}
                        >
                           <h4>Connecting...</h4>
                           <img src={loadingAnim} alt="Loading Animation" width={80} />
                           <p>{personas[personaSelection].name}</p>
                        </div>
                        <video
                           className="interview-video square-bottom"
                           ref={didVideo.ref}
                           style={{
                              background: "transparent",
                              width: "100%",
                              height: "100%",
                              // backgroundImage: `url("${personas[personaSelection].url}")`,
                              // backgroundPosition: "center",
                              // backgroundSize: "cover",
                              borderBottom: 0,
                              position: "relative"
                           }}
                        />
                        <div className="video-caption-overlay">
                           <div
                              className={`overlay-text ${
                                 showAICaptions ? "d-none d-lg-block" : ""
                              }`}
                              hidden={!showAICaptions}
                           >
                              {lastQuestion}
                           </div>
                           <div className="d-flex justify-content-between align-items-center mt-1">
                              <div className="overlay-text" style={{ fontSize: "0.75rem" }}>
                                 {personas[personaSelection].name}
                              </div>
                              <div
                                 className="d-none d-lg-block cursor-pointer"
                                 style={{ opacity: "85%" }}
                                 onClick={() => setShowAICaptions(v => !v)}
                              >
                                 {showAICaptions ? (
                                    <BadgeCcFill size="1.4rem" color="white" />
                                 ) : (
                                    <BadgeCc size="1.4rem" color="white" />
                                 )}
                              </div>
                           </div>
                        </div>
                     </div>
                  )}
                  <audio hidden src={ringAudio} ref={ringerRef} />
                  <div
                     className="round-bottom p-3"
                     style={{
                        border: "1px solid lightgrey",
                        borderTopColor: "#3D3D3D",
                        backgroundColor: "#292929",
                        maxHeight: "12rem",
                        overflow: "auto",
                        minHeight: "7rem",
                        color: "white",
                        flexGrow: 1,
                        scrollbarColor: "lightgrey #3D3D3D"
                     }}
                  >
                     <div>
                        <small>
                           <strong>{captionLabel}</strong>
                        </small>
                     </div>
                     <div>{captionText}</div>
                  </div>
               </div>
               <div className="video-col d-flex flex-column flex-basis-25 interview-vert-gap">
                  <div className="position-relative">
                     <video
                        className="interview-video"
                        ref={webcamRef}
                        hidden={videoState === "Blocked"}
                     />
                     <div
                        className="interview-video flex-center"
                        hidden={videoState !== "Blocked"}
                        style={{
                           position: "relative",
                           // top: 0,
                           // left: 0,
                           backgroundColor: "#1F1F1F",
                           border: 0
                        }}
                     >
                        <div className="flex-center gap-3">
                           <img src="/avatar-01.svg" alt="Avatar Placeholder" width={75} />
                           <div style={{ color: "white" }}>{userName}</div>
                        </div>
                     </div>
                     <div className="name-display">{userName}</div>
                  </div>
                  <div className="interview-stats position-relative d-flex flex-column gap-3">
                     <div style={{ maxWidth: "85%" }}>
                        <div className="primary-text fw-bold">{company}</div>
                        <div>{jobTitle}</div>
                     </div>
                     <div className="d-flex gap-3">
                        <div className="icon-button gap-0" style={{ flexGrow: 2 }}>
                           <div className="primary-text fw-bold">
                              {interviewDuration.toLocaleTimeString("en-US", {
                                 //hour: "numeric",
                                 minute: "2-digit",
                                 second: "2-digit",
                                 timeZone: "UTC",
                                 hourCycle: "h23"
                              })}
                           </div>
                           <div className="icon-button-subtext">Interview Time</div>
                        </div>
                        <div className="icon-button">
                           <CameraVideoFill
                              size={16}
                              color={accessColors[videoState]}
                              className="mt-1"
                           />
                           <div className="icon-button-subtext">
                              Video {videoState === "Allowed" ? "Ready" : "Off"}
                           </div>
                        </div>
                        <div className="icon-button">
                           <MicFill size={16} color={accessColors[audioState]} className="mt-1" />
                           <div className="icon-button-subtext">
                              Mic {audioState === "Allowed" ? "Ready" : "Off"}
                           </div>
                        </div>
                     </div>
                     <Button
                        className="primary-text fw-bold"
                        disabled={audioState !== "Allowed" || streamState === "Finished"}
                        variant={streamState !== "Connected" ? "success" : "danger"}
                        onClick={streamState !== "Connected" ? createConnection : stopConnection}
                        style={{
                           color: "white",
                           // fontSize: "1.3rem",
                           padding: "1rem"
                        }}
                     >
                        <div className="d-flex gap-2 align-items-center justify-content-center">
                           {audioState !== "Allowed" ? (
                              <>Enable Mic to start interview</>
                           ) : streamState !== "Connected" ? (
                              <>
                                 <TelephoneFill size={20} />
                                 <div>Start Interview</div>
                              </>
                           ) : (
                              <>
                                 <TelephoneXFill size={20} />
                                 <div>Stop Interview</div>
                              </>
                           )}
                        </div>
                     </Button>
                     <div hidden={!showPauseButton && !buttonSubmit}>
                        <hr className="mt-0" />
                        <div className="d-flex justify-content-center gap-3">
                           <Button
                              variant={pauseRecognition ? "danger" : "primary"}
                              onClick={() => setPauseRecognition(v => !v)}
                              hidden={!showPauseButton}
                              style={{
                                 color: pauseRecognition ? "white" : "white"
                              }}
                           >
                              {pauseRecognition ? (
                                 <>
                                    <MicOff /> Mute
                                 </>
                              ) : (
                                 <>
                                    <Mic /> Mute
                                 </>
                              )}
                           </Button>
                           <Button
                              disabled={streamState !== "Connected"}
                              onClick={submitAnswer}
                              hidden={!buttonSubmit}
                              style={{ color: "white" }}
                           >
                              Send Answer
                           </Button>
                           {/* <Button
                              onClick={() => {
                                 axios
                                    .get(config.urls.getSpeechToken, {
                                       params: { user }
                                    })
                                    .then(
                                       res => {
                                          console.log(res.data);
                                          const speechInfo = res.data;
                                          setSpeechToken(speechInfo.token);
                                          setSpeechRegion(speechInfo.region);
                                          startSpeechRecognizer(
                                             speechInfo.token,
                                             speechInfo.region
                                          );
                                       },
                                       err => console.log(err)
                                    );
                              }}
                           >
                              Start Audio (Debug)
                           </Button> */}
                        </div>
                     </div>
                     <div
                        className="d-flex flex-column gap-2 align-items-end top-right-position"
                        style={{
                           top: "1rem",
                           right: "1rem"
                        }}
                     >
                        <GearFill
                           className="cursor-pointer"
                           onClick={() => setShowSettings(s => !s)}
                           size={20}
                        />
                        <div
                           className="border rounded p-2"
                           style={{ backgroundColor: "white" }}
                           hidden={!showSettings}
                        >
                           <div
                              className="d-flex flex-column align-items-end gap-1"
                              style={{ fontSize: "0.75rem" }}
                           >
                              <div>
                                 Persona:{" "}
                                 <select
                                    disabled={streamState !== "Disconnected"}
                                    value={personaSelection}
                                    onChange={e => setPersonaSelection(e.target.value)}
                                 >
                                    {Object.keys(personas).map(p => (
                                       <option value={p} key={p}>
                                          {p}
                                       </option>
                                    ))}
                                 </select>
                              </div>
                              <div>
                                 Tone:{" "}
                                 <select>
                                    <option>Default</option>
                                    <option>Friendly</option>
                                    <option>Difficult</option>
                                 </select>
                              </div>
                              <div
                                 className="cursor-pointer"
                                 onClick={() => setShowAICaptions(v => !v)}
                              >
                                 <input
                                    className="cursor-pointer"
                                    type="checkbox"
                                    checked={showAICaptions}
                                    readOnly
                                 />{" "}
                                 Show AI Captions
                              </div>
                              <div
                                 className="cursor-pointer"
                                 onClick={() => setShowUserCaptions(v => !v)}
                              >
                                 <input
                                    className="cursor-pointer"
                                    type="checkbox"
                                    checked={showUserCaptions}
                                    readOnly
                                 />{" "}
                                 Show Self Captions
                              </div>
                              <div
                                 className="cursor-pointer"
                                 onClick={() => setIgnoreMicDuringAI(v => !v)}
                              >
                                 <input
                                    className="cursor-pointer"
                                    type="checkbox"
                                    checked={ignoreMicDuringAI}
                                    readOnly
                                 />{" "}
                                 Disable mic while AI is speaking
                              </div>
                              <div
                                 className="cursor-pointer"
                                 onClick={() => setShowPauseButton(v => !v)}
                              >
                                 <input
                                    className="cursor-pointer"
                                    type="checkbox"
                                    checked={showPauseButton}
                                    readOnly
                                 />{" "}
                                 Show Mute Button
                              </div>
                              <div
                                 className="cursor-pointer"
                                 onClick={() => setButtonSubmit(v => !v)}
                              >
                                 <input
                                    className="cursor-pointer"
                                    type="checkbox"
                                    checked={buttonSubmit}
                                    readOnly
                                 />{" "}
                                 Show Send Answer Button
                              </div>
                              <div
                                 className="cursor-pointer"
                                 onClick={() => setShowMessageHistory(v => !v)}
                              >
                                 <input
                                    className="cursor-pointer"
                                    type="checkbox"
                                    checked={showMessageHistory}
                                    readOnly
                                 />{" "}
                                 Show Conversation History
                              </div>
                              <div>
                                 Delay (Seconds):{" "}
                                 <input
                                    className="rounded"
                                    type="number"
                                    size={5}
                                    min={1}
                                    value={audioTimeoutDelay}
                                    onChange={e => setAudioTimeoutDelay(e.target.value)}
                                 />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="interview-row">
               <div className="interview-stats" style={{ width: "100%" }}>
                  <h4
                     style={{
                        marginBottom: "1rem",
                        fontWeight: 600
                     }}
                  >
                     Interview Scorecard
                  </h4>
                  <div
                     style={{
                        maxWidth: "1280px",
                        marginBottom: "1.5rem"
                     }}
                  >
                     Our AI coach analyzes and improves your interview skills with targeted feedback
                     to sharpen your answers, communication and boost confidence.
                  </div>
                  <Coach
                     inactive={!preserveCoachStats}
                     pace={coachScores.pace}
                     answers={coachScores.answers}
                     sentiment={coachScores.sentiment}
                     decisive={coachScores.decisive}
                     fillerWords={fillerWordCounts}
                     fillerWordScore={coachScores.fillerWords}
                     repetitiveLanguage={coachScores.repetitiveLanguage}
                     inclusiveness={coachScores.inclusiveness}
                  />
               </div>
            </div>
            {showMessageHistory ? (
               <div className="interview-row">
                  <div className="interview-stats" style={{ width: "100%" }}>
                     <h4 style={{ fontWeight: 600 }}>Conversation History</h4>
                     <div className="table-expand-area-item mt-3 gap-3">
                        {messageHistory.slice(1).map((msg, i) => (
                           <div key={i}>
                              <div>
                                 <small>
                                    <strong>
                                       {msg.role === "assistant"
                                          ? personas[personaSelection].name
                                          : userName}
                                    </strong>
                                 </small>
                              </div>
                              <div>{msg.content}</div>
                           </div>
                        ))}
                     </div>
                  </div>
               </div>
            ) : null}
         </div>
      </div>
   );
}

export default Interview;

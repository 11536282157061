import React, { useCallback, useEffect, useState } from "react";
import useUserParam from "../hooks/useUserParam";
import axios from "axios";
import config from "../config";
import { Button, FormControl, Table } from "react-bootstrap";
import PreparednessArea from "../components/PreparednessArea";
import usePagination from "../hooks/usePagination";
import { X } from "react-bootstrap-icons";

function Preparedness() {
   const user = useUserParam();

   const [filter, setFilter] = useState("");
   const [filteredList, setFilteredList] = useState([]);
   const [historyDataRaw, setHistoryDataRaw] = useState([]);
   const [expanded, setExpanded] = useState();
   const { subset: historyData, Controller } = usePagination(filteredList);

   const generateAnswers = useCallback(
      row => {
         // Get updated answers
         axios.post(config.urls.preparednessTips, { user, prepId: row.id }).then(
            res => {
               setHistoryDataRaw(hd => {
                  const updateIndex = hd.findIndex(r => r.id === row.id);
                  const updateArr = [...hd];
                  updateArr[updateIndex].generatedAnswers = res.data.tips;
                  return updateArr;
               });
            },
            err => {
               console.warn("Failed to generate preparedness tips");
               console.error(err);
            }
         );
      },
      [user]
   );

   useEffect(() => {
      axios.get(config.urls.preparedness, { params: { user } }).then(
         res => {
            const data = res.data.list.sort((a, b) => new Date(b.created) - new Date(a.created));
            console.log(data);
            setHistoryDataRaw(data);
         },
         err => {
            console.warn("Failed to get preparedness history");
            console.error(err);
         }
      );
   }, [user]);

   useEffect(() => {
      const sublist = historyDataRaw.filter(
         r =>
            r.company?.toLowerCase().includes(filter) || r.jobTitle?.toLowerCase().includes(filter)
      );
      setFilteredList(sublist);
   }, [filter, historyDataRaw]);

   return (
      <div>
         <div className="d-flex p-2 align-items-center">
            <FormControl
               value={filter}
               placeholder="Search Company or Job Title"
               onChange={e => setFilter(e.target.value.toLowerCase())}
            />
            <X
               size={25}
               className={`cursor-pointer ${!filter ? "d-none" : ""}`}
               style={{ position: "absolute", right: "1rem" }}
               onClick={() => setFilter("")}
            />
         </div>
         <Table className="w-100 h-100" borderless>
            <colgroup>
               <col
                  span="1"
                  style={{
                     width: "4%"
                  }}
               />
               <col
                  span="2"
                  style={{
                     width: "30%"
                  }}
               />
               <col
                  span="1"
                  style={{
                     width: "16%"
                  }}
               />
               <col
                  span="1"
                  style={{
                     width: "16%"
                  }}
               />
            </colgroup>
            <thead>
               <tr>
                  <th>#</th>
                  <th>Company</th>
                  <th>Job Title</th>
                  <th>Date</th>
                  <th></th>
               </tr>
            </thead>
            <tbody>
               {historyData.map(row => {
                  const created = new Date(row.created);
                  return (
                     <React.Fragment key={row.id}>
                        <tr style={{ borderTop: "2px solid whitesmoke" }}>
                           <td data-col="ID#">{row.id}</td>
                           <td data-col="Company">{row.company || "\u00a0"}</td>
                           <td data-col="Job Title">{row.jobTitle || "\u00a0"}</td>
                           <td data-col="Time">
                              {created.toLocaleDateString("default", {
                                 day: "numeric",
                                 month: "numeric",
                                 year: "numeric"
                              })}
                              <br />
                              {created.toLocaleTimeString("default", {
                                 hour: "numeric",
                                 minute: "2-digit"
                              })}
                           </td>
                           <td>
                              <Button
                                 className="list-button"
                                 variant="outline-secondary"
                                 onClick={() => setExpanded(e => (e !== row.id ? row.id : null))}
                              >
                                 {expanded !== row.id ? "Show " : "Hide "}
                                 Questions and Answers
                              </Button>
                           </td>
                           <td></td>
                        </tr>
                        <tr hidden={row.id !== expanded}>
                           <td></td>
                           <td colSpan={4} className="pb-4">
                              <PreparednessArea
                                 data={row}
                                 onGenerateAnswers={() => generateAnswers(row)}
                              />
                           </td>
                           <td></td>
                        </tr>
                     </React.Fragment>
                  );
               })}
            </tbody>
         </Table>
         <Controller />
      </div>
   );
}

export default Preparedness;

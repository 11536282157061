import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";

function AnswerButtonSection({ answerContent, onClick }) {
   const [hideAnswers, setHideAnswers] = useState(false);
   const [generating, setGenerating] = useState(false);

   return !answerContent ? (
      <Button
         className="align-self-start mt-3"
         style={{ borderColor: "#1d0b81", backgroundColor: "#1d0b81", color: "white" }}
         onClick={() => {
            setGenerating(true);
            onClick();
         }}
         disabled={generating}
      >
         Get answer tips for interview questions {generating ? <Spinner size="sm" /> : null}
      </Button>
   ) : (
      <>
         <hr />
         <div>
            <strong>Answer Tips</strong>
            &emsp;
            <Button size="sm" onClick={() => setHideAnswers(h => !h)}>
               {hideAnswers ? "View" : "Hide"}
            </Button>
         </div>
         <p hidden={hideAnswers} className="text-section">
            {answerContent}
         </p>
      </>
   );
}

export default AnswerButtonSection;
